<template>
  <div class="profile-sidebar-mob">
    <div class="profile-sidebar-menu">
      <!-- <router-link tag="a" to="/profile" class="profile" :class="{'active': $route.path === '/profile'}">{{ $t('sidebar.profile') }}</router-link> -->
      <!-- <router-link tag="a" to="/notes" class="notes" :class="{'active': $route.path === '/notes'}">{{ $t('sidebar.notes') }}</router-link> -->
      <!-- <a href="/profile" class="notes" :class="{'active': $route.path === '/notes'}">{{ $t('sidebar.notes') }}</a> -->
      <router-link tag="a" to="/testing" class="testing"
                   :class="{'active': $route.path === '/testing', 'active': $route.path === '/quizzes-list'}">
        {{ $t('sidebar.testing') }}
      </router-link>
      <!-- <router-link tag="a" to="/notification" class="notifications" :class="{'active': $route.path === '/notification'}">{{ $t('sidebar.notifications') }}</router-link> -->
      <!-- <a href="/profile" class="notifications">{{ $t('sidebar.notifications') }}</a> -->
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  props: ['title'],
  name: 'ProfileFooter',
  data() {
    return {
      logged: false,
      collapsed: true,
      avatar: '',
      user: {},
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') == '');

    // this.$http.get(window.API_ROOT + '/api/user/edit')
    //     .then((res) => {
    //       this.user = res.data.data;
    //       this.avatar = res.data.link;
    //       sessionStorage.setItem('avatar-url', res.data.link);
    //     })
    //     .catch((e) => {
    //       if (e.status === 401) {
    //         this.$router.push('/')
    //       }
    //     });
  },
  methods: {
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute);
    },
    logoutMethod() {
      this.logout();
      localStorage.setItem('token', '');
      localStorage.setItem('user', '');
      window.location = '/';
    },
    clickaway() {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  },
}
</script>
<style>

.profile-sidebar-mob {
  height: 56px;
  padding: 0 8px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #FFFFFF;
  filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.07));
}

.profile-sidebar-mob .profile-sidebar-menu {
  display: none;
  justify-content: space-evenly;
  height: 56px;
  width: 100%;
}

.profile-sidebar-mob .profile-sidebar-menu a,
.profile-sidebar-mob .profile-sidebar-menu a.active {
  padding-left: 0;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  padding: 34px 0 0 0;
  background-position: center 8px;
  background-color: #fff;
}

.profile-sidebar-mob .profile-sidebar-menu a.active:after {
  display: none;
}

@media screen and (max-width: 992px) {
  .profile-sidebar-mob .profile-sidebar-menu {
    display: flex;
  }
}
</style>
