<template>
  <div class="component-alert container">
    <div class="length mx-auto text-left">
      <el-alert
          :type="type"
          :description="description"
          show-icon>
      </el-alert>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    description: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'warning'
    },
    duaration: {
      type: Number,
      default: 10000
    }
  },
  methods: {
    close() {
      this.$destroy()
    }
  },
  mounted() {
    var timer = setTimeout(() => {
      clearTimeout(timer)
      this.close()
    }, this.duaration)
  },
  destroyed() {
    this.$el.remove()
  }
}
</script>

<style>
.component-alert {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  height: 0;
  z-index: 999999999999999999;
  text-align: center;
}

.component-alert > .alert {
  display: inline-block;
}

.component-alert .close {
  position: relative;
  right: -6px;
}

.length {
  width: 28%
}

@media screen and (max-width: 992px) {
  .length {
    width: 50%
  }
}

@media screen and (max-width: 767px) {
  .length {
    width: 100%
  }
}
</style>