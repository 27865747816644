<template>
  <footer class="footer pb-0 footer-block">
    <div class="container mx-auto text-white pb-4 footer-desktop">
      <div class="row justify-content-between">
        <div class="col-lg-3 col-md-12">
          <div class="footer-logo">

          </div>
        </div>
        <div class="col-lg-3 col-md-12">
          <ul class="footer_menu">
            <!-- <li>
                <router-link tag="a" to="/">{{ $t('footer.contacts') }}</router-link>
            </li> -->
            <li>
              <router-link tag="a" to="/terms-of-use">
                Главная
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/privacy-policy">
                О проекте
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/moderation-policy">
                Участникам тестирования
              </router-link>
            </li>

          </ul>
        </div>
        <div class="col-lg-2 col-md-12">
          <ul class="footer_menu">
            <li>
              <router-link tag="a" to="/terms-of-use">
                Педагогам
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/privacy-policy">
                Родителям
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/moderation-policy">
                Центры "Qabilet"
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-12">
          <ul class="footer_menu">
            <li>
              <router-link tag="a" to="/terms-of-use">
                FAQ
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/privacy-policy">
                Контакты
              </router-link>
            </li>
          </ul>
        </div>
        <div class=" d-none col-lg-4 col-md-12 d-none d-lg-block invisible">
          <div class="mobile-apps-wrap d-none">
            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom footer-bottom-links footer-bottom-desktop">
      <div class="container">
        <p class="mb-0">© {{ date }} Qabilet. {{ $t('footer.copyright') }}</p>
        <div class="footer-links-block">
          <a class="youtube-link">

          </a>
          <a class="facebook-link">

          </a>
          <a class="instagram-link">

          </a>
          <a class="phone-link">
            8 727 310 02 58
          </a>
        </div>
      </div>
    </div>


    <div class="container mx-auto text-white pb-4 footer-mobile">
      <div class="row justify-content-between">
        <div class="col-md-12">
          <div class="footer-logo">

          </div>
        </div>
        <div class="container">
          <div class="footer-links-mobile">
            <a class="youtube-link youtube-link-mobile">

            </a>
            <a class="facebook-link facebook-link-mobile">

            </a>
            <a class="instagram-link instagram-link-mobile">

            </a>
            <a class="phone-link phone-link-mobile">
              8 727 310 02 58
            </a>
          </div>
        </div>

        <div class="d-flex w-100">
          <div class="col-md-6">
            <ul class="footer_menu">
              <li>
                <router-link tag="a" to="/terms-of-use">
                  Главная
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/privacy-policy">
                  О проекте
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/moderation-policy">
                  Участникам тестирования
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/terms-of-use">
                  Педагогам
                </router-link>
              </li>

            </ul>
          </div>
          <div class="col-md-6">
            <ul class="footer_menu">
              <li>
                <router-link tag="a" to="/privacy-policy">
                  Родителям
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/moderation-policy">
                  Центры "Qabilet"
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/terms-of-use">
                  FAQ
                </router-link>
              </li>
              <li>
                <router-link tag="a" to="/privacy-policy">
                  Контакты
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class=" d-none col-lg-4 col-md-12 d-none d-lg-block invisible">
          <div class="mobile-apps-wrap d-none">
            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom footer-bottom-links footer-bottom-mobile">
      <div class="container">
        <p>© {{ date }} Qabilet. {{ $t('footer.copyright') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>

<style>

.footer-mobile {
  display: none;
}

.footer-bottom-mobile {
  display: none;
}

@media screen and (max-width: 992px) {

  .footer-mobile {
    display: block;
  }

  .footer-bottom-mobile {
    display: block;
  }

  .footer-desktop, .footer-bottom-desktop {
    display: none;
  }

  .footer-block {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding-top: 24px !important;
    background: #266390 !important;
  }

  .footer-mobile .footer-logo {
    height: 32px;
    margin-bottom: 20px;
  }

  .footer-links-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
  }

  .footer-mobile .footer_menu {
    text-align: left;
  }

  .footer-bottom-mobile {
  }

  .footer-bottom-mobile p {
    margin: 0;
  }

  .footer .footer-bottom {
    border-top: 1px solid #EBEBEB !important;
    padding: 24px 0 !important;
  }

  .footer-bottom-links .container {
    justify-content: center !important;
  }

}

.our-app {
  display: none;
}

</style>
