<template>
  <div>
    <nav class="component-navbar main-navbar navbar white navbar-expand-lg">
      <div class="container">
        <button :class="{open: !collapsed}" type="button" class="navbar-toggler collapsed pl-0"
                @click="collapsed=!collapsed" v-clickaway="clickaway">
          <span class="navbar-toggler-icon"><span class="menu-btn-burger" /></span>
        </button>
        <a href="#" class="navbar-brand mob-hidden p-0">
          <div class="burger-menu">
            <div class="burger-menu-item"></div>
            <div class="burger-menu-item"></div>
            <div class="burger-menu-item"></div>
          </div>
        </a>
        <a href="https://elbasyacademy.kz/" class="navbar-brand mob-hidden p-0">
          <img width="88" src="/images/EA_logo_stamp-blue.svg" style="padding: 7px">
        </a>
        <div class="d-flex flex-row align-items-center">
          <router-link to="/" class="navbar-brand p-0">
            <img width="88" src="/images/qabilet-blue-logo.svg">
          </router-link>
          <a href="https://elbasyacademy.kz/" class="navbar-brand navbar-brand--mob">
            <img width="60" src="/images/EA_logo_stamp.svg">
          </a>
        </div>

        <a href="#" class="navbar-brand special-view-button-mobile">
          <img width="22" src="/images/special-view.svg">
        </a>

        <div class="navbar-collapse desktop-menu">
          <ul class="navbar-nav ml-auto">
            <li class="navbar-phone">
              <a href="tel:87273100258"><i class="phone-icon"></i>8 727 310 02 58</a>
              <div>звонок платный</div>
            </li>
            <li class="navbar-item button sign-up">
              <a href="#"><i class="reg-icon"></i>Регистрация</a>
            </li>
            <li class="navbar-item button sign-in">
              <a href="#"><i class="door-icon"></i>Вход</a>
            </li>
            <li class="navbar-item">
              <a href="#">RU<i class="lang-icon"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'Navbar',
  data() {
    return {
      logged: false,
      collapsed: true
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
  },
  methods: {
    goToProfile() {
      if (localStorage.getItem('role') == 'student') {
        this.$router.push(this.$route.query.redirect || '/profile')
      }
      else if(localStorage.getItem('role') == 'center-coach'){
        this.$router.push(this.$route.query.redirect || '/profile-manager')
      }
      else {
        this.$router.push(this.$route.query.redirect || '/profile-parent')
      }

    },
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute)
    },
    logoutMethod() {
      this.logged = false;
      this.logout()
    },
    clickaway() {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  },
}
</script>

<style>
.burger-menu {
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.burger-menu-item {
  width: 100%;
  height: 4px;
  background-color: #1090CB;
  margin: 5px 0;
  border-radius: 2px;
}

@media screen and (max-width: 991px) {
  .nav-item {
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .navbar.white.main-navbar .container {
    max-width: 1400px;
  }
}
</style>
