<template>
  <div class="navbar-light">
    <nav class="component-navbar main-navbar navbar navbar-expand-lg">
      <div class="container navbar-desktop">
        <button :class="{open: !collapsed}" type="button" class="navbar-toggler collapsed pl-0"
                @click="collapsed=!collapsed" v-clickaway="clickaway">
          <span class="navbar-toggler-icon"><div class="menu-btn-burger"></div></span>
        </button>
        <a href="#" class="navbar-brand mob-hidden p-0">
          <div class="burger-menu">
            <div class="burger-menu-item"></div>
            <div class="burger-menu-item"></div>
            <div class="burger-menu-item"></div>
          </div>
        </a>
        <a href="https://elbasyacademy.kz/" class="navbar-brand mob-hidden p-0">
          <!-- <img width="44" src="/images/logo_el_umiti.png"> -->
          <img width="88" src="/images/elumiti-light-nav.svg" style="padding: 7px">
        </a>
        <div class="d-flex flex-row align-items-center">
          <router-link to="/" class="navbar-brand p-0">
            <img width="88" src="/images/Qabilet-Final-light-nav.png">
          </router-link>
          <a href="https://elbasyacademy.kz/" class="navbar-brand navbar-brand--mob">
            <!-- <img width="44" src="/images/logo_el_umiti.png"> -->
            <img width="60" src="/images/EA_logo_stamp.svg">
          </a>
        </div>

        <a href="#" class="navbar-brand special-view-button-mobile">
          <img width="22" src="/images/special-view.svg">
        </a>

        <div class="navbar-collapse desktop-menu">
          <ul class="navbar-nav ml-auto">
            <li class="navbar-phone">
              <a href="tel:87273100258"><i class="phone-icon"></i>8 727 310 02 58</a>
              <div>звонок платный</div>
            </li>
            <li class="navbar-item login">
              <a href="#"><i class="door-icon"></i>Вход</a>
            </li>
            <li class="navbar-item reg">
              <a href="#"><i class="reg-icon"></i>Регистрация</a>
            </li>
            <li class="navbar-item langs">
              <a href="#">RU<i class="lang-icon"></i></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="container navbar-mobile">
        <div>
          <a href="https://elbasyacademy.kz/" class="navbar-brand p-0">
            <img width="50" src="/images/elumiti-light-nav.svg" style="padding: 7px">
          </a>
          <router-link to="/" class="navbar-brand p-0">
            <img width="78" src="/images/Qabilet-Final-light-nav.png">
          </router-link>
        </div>

        <div class="d-flex align-items-center">
          <div class="mobile-langs">
            <span>RU</span>
          </div>
          <div class="burger-menu">
            <div class="burger-menu-item"></div>
            <div class="burger-menu-item"></div>
            <div class="burger-menu-item"></div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Navbar',
  data() {
    return {
      logged: false,
      collapsed: true
    }
  },
  mounted() {
    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');
  },
  methods: {
    goToProfile() {
      if (localStorage.getItem('role') == 'student') {
        this.$router.push(this.$route.query.redirect || '/profile')
      }
      else if(localStorage.getItem('role') == 'center-coach'){
        this.$router.push(this.$route.query.redirect || '/profile-manager')
      }
      else {
        this.$router.push(this.$route.query.redirect || '/profile-parent')
      }

    },
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute)
    },
    logoutMethod() {
      this.logged = false;
      this.logout()
    },
    clickaway() {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  },
}
</script>
<style scoped>

.burger-menu {
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;
}

.burger-menu-item {
  width: 100%;
  height: 4px;
  background-color: #1090CB;
  margin: 5px 0;
  border-radius: 2px;
}

.navbar-phone a {
  color: #1090CB;
}

.navbar-phone div {
  color: #1090CB;
}

.navbar-light .navbar-item {
  border: 1px solid #1090CB;
}

.navbar-light .login {
  background-color: #E7F4FA;
  border: 1px solid #E7F4FA;
}

.navbar-light .login a {
  color: #1090CB;
  font-size: 16px;
  font-weight: 700;
}

.navbar-light .langs a {
  color: #1090CB;
  font-size: 16px;
  font-weight: 700;
}

.navbar-light .navbar-nav .reg {
  background-color: #1090CB;
  border: 1px solid #1090CB;
  color: #FFFFFF;
}

.main-navbar .navbar-item .door-icon {
  display: block;
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url("../../public/images/pull-door-light-nav.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.main-navbar .navbar-item .lang-icon {
  display: block;
  float: right;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  background-image: url("../../public/images/down-one-light-nav.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.main-navbar .navbar-phone .phone-icon {
  display: block;
  float: left;
  width: 18px;
  height: 30px;
  margin-right: 10px;
  background-image: url("../../public/images/phone-light-nav.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.navbar .navbar-mobile {
  display: none;
}

@media screen and (max-width: 991px) {
  .nav-item {
    padding-left: 15px;
  }
}

@media screen and (max-width: 992px) {
  .navbar .navbar-desktop {
    display: none;
  }

  .navbar .navbar-mobile {
    display: flex;
    justify-content: space-between;
  }

  .mobile-langs {
    border: 1px solid #1090CB;
    border-radius: 5px;
    padding: 6px 30px 6px 10px;
    font-size: 10px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    color: #1090CB;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 15px;
  }

  .mobile-langs::after {
    position: absolute;
    width: 24px;
    height: 24px;
    content: "";
    top: 2px;
    right: 0px;
    background-image: url("../../public/images/down-one-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .navbar-mobile .burger-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 22px;
  }

  .navbar-mobile .burger-menu-item {
    width: 100%;
    height: 4px;
    background-color: #1090CB;
    margin: 0;
    border-radius: 2px;
  }
}
</style>
